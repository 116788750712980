import React from 'react'
import Link from '../components/localized-link'
import classNames from 'classnames'

const Button = ({ className, kind = 'default', ...props }) => {
  const isAlpha = kind === 'alpha'
  const isBeta = kind === 'beta'

  return (
    <Link
      className={classNames(
        'c-button',
        { 'c-button--alpha': isAlpha },
        className
      )}
      {...props}
    />
  )
}

export default Button
