import React from 'react'
import _ from 'lodash'
import { useAgency } from '../hooks/use-agency'
import { useLocale } from '../hooks/use-locale'

export function AgencyAddress() {
  const { settings } = useAgency()
  const locale = useLocale()
  const [countriesByCode, setCountriesByCode] = React.useState({})
  React.useEffect(() => {
    import(`../locales/countries/${locale}.json`).then((countries) => {
      setCountriesByCode(_.groupBy(countries, 'alpha2'))
    })
  }, [locale])
  return <span>{formatAddress(settings, countriesByCode)}</span>
}

function formatAddress(settings, countriesByCode) {
  const part1 = [
    settings.contact_street,
    settings.contact_number,
    settings.contact_box,
  ]
    .filter(Boolean)
    .join(' ')
  const part2 = [settings.contact_zip, settings.contact_city]
    .filter(Boolean)
    .join(' ')

  const country =
    countriesByCode[settings.contact_country?.toLowerCase()]?.[0].name
  return [part1, part2, country ?? settings.contact_country]
    .filter(Boolean)
    .join(', ')
}
