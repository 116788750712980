import React from 'react'
import HeaderNavigation from './navigation'
import LocaleSelector from './locale-selector'
import classNames from 'classnames'
import Logo from './logo'
import { useAgency } from '../hooks/use-agency'

const Header = ({ logoId, siteTitle, currentPath, locale, borderless }) => {
  const currentPathWithoutLocale = currentPath.split('/').slice(2).join('/')
  const { agency } = useAgency()
  const [isOpen, setOpen] = React.useState(false)

  return (
    <div
      className={classNames(
        'c-row c-row--collapsed',
        borderless ? 'c-row--transparent' : 'c-row--color-adapt'
      )}
    >
      <div className="o-container">
        <div className="o-grid">
          <div className="o-grid__item c-navbar">
            <div className="c-navbar__item c-navbar__item--first">
              <Logo siteTitle={siteTitle} logoId={logoId} />
            </div>
            <div className="c-navbar__item c-navbar__item--last">
              <HeaderNavigation currentPath={`/${currentPathWithoutLocale}`} />
              <LocaleSelector locale={locale ?? agency.locale} />
              <a
                href="#"
                onClick={() => setOpen(!isOpen)}
                className="c-navbar__toggle"
              >
                <MenuIcon />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classNames('c-navigation-mobile', { 'is-hidden': !isOpen })}
      >
        <div className="c-navigation-mobile__header c-row c-row--color-adapt">
          <div className="c-navbar__item c-navbar__item--first">
            <Logo siteTitle={siteTitle} logoId={logoId} />
          </div>
          <div className="c-navbar__item c-navbar__item--last">
            <a
              href="#"
              onClick={() => setOpen(!isOpen)}
              className="c-navbar__toggle"
            >
              <CloseIcon />
            </a>
          </div>
        </div>
        <HeaderNavigation currentPath={`/${currentPathWithoutLocale}`} />
        <LocaleSelector mobile />
      </div>
    </div>
  )
}

export default Header

function MenuIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40">
      <rect width="20" height="1" x="10" y="14" fill="currentColor"></rect>
      <rect width="20" height="1" x="10" y="20" fill="currentColor"></rect>
      <rect width="20" height="1" x="10" y="26" fill="currentColor"></rect>
    </svg>
  )
}

function CloseIcon() {
  return (
    <svg width="40" height="40">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M13.282 13.075l14.143 14.143-.707.707-14.143-14.143z" />
        <path d="M12.575 27.218l14.143-14.143.707.707-14.143 14.143z" />
      </g>
    </svg>
  )
}
