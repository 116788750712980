import React from 'react'
import classNames from 'classnames'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useAgency } from '../hooks/use-agency'
import { usePageContext } from '../hooks/use-page-context'
import Link from './localized-link'

// is-selected
const Navigation = ({ currentPath, intl }) => {
  const { locale } = intl
  const { settings } = useAgency()
  const { transaction_types } = settings
  const { hasProjects } = usePageContext()

  return (
    <nav className="c-nav-main print-display-none">
      <ul className="o-list c-nav-main__list">
        {transaction_types !== 'let' && (
          <li
            className={classNames('c-nav-main__item', {
              'is-selected': currentPath.startsWith('/sale'),
            })}
          >
            <Link to="/sale" className="c-nav-main__link">
              <FormattedMessage id="page.for-sale" />
            </Link>
          </li>
        )}
        {transaction_types !== 'sale' && (
          <li
            className={classNames('c-nav-main__item', {
              'is-selected': currentPath.startsWith('/let'),
            })}
          >
            <Link to="/let" className="c-nav-main__link">
              <FormattedMessage id="page.to-let" />
            </Link>
          </li>
        )}
        {hasProjects && (
          <li
            className={classNames('c-nav-main__item', {
              'is-selected': currentPath.startsWith('/projects'),
            })}
          >
            <Link to="/projects" className="c-nav-main__link">
              <FormattedMessage id="page.projects" />
            </Link>
          </li>
        )}
        {settings[`about_${locale}`]?.trim() && (
          <li
            className={classNames('c-nav-main__item', {
              'is-selected': currentPath.startsWith('/about'),
            })}
          >
            <Link to="/about" className="c-nav-main__link">
              <FormattedMessage id="page.about" />
            </Link>
          </li>
        )}
        {settings[`description_${locale}`]?.trim() && (
          <li
            className={classNames('c-nav-main__item', {
              'is-selected': currentPath.startsWith('/services'),
            })}
          >
            <Link to="/services" className="c-nav-main__link">
              <FormattedMessage id="page.services" />
            </Link>
          </li>
        )}
        <li
          className={classNames('c-nav-main__item', {
            'is-selected': currentPath.startsWith('/personal-updates'),
          })}
        >
          <Link to="/personal-updates" className="c-nav-main__link">
            <FormattedMessage id="page.get-personal-updates" />
          </Link>
        </li>
        <li
          className={classNames('c-nav-main__item', {
            'is-selected': currentPath.startsWith('/contact'),
          })}
        >
          <Link to="/contact" className="c-nav-main__link">
            <FormattedMessage id="page.contact" />
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default injectIntl(Navigation)
