import React from 'react'
import TwitterIcon from '../../assets/img/icons/ico-twitter.svg'
import FacebookIcon from '../../assets/img/icons/ico-facebook.svg'
import LinkedInIcon from '../../assets/img/icons/ico-linkedin.svg'
import InstagramIcon from '../../assets/img/icons/ico-instagram.svg'

export default ({ settings }) => {
  return (
    <ul className="c-nav-list c-nav-list--social">
      {settings.links_twitter && (
        <li>
          <a
            href={settings.links_twitter}
            title="Twitter"
            className="c-nav-list__link"
            target="_blank"
          >
            <i className="o-icon o-icon--medium">
              <TwitterIcon />
            </i>
          </a>
        </li>
      )}
      {settings.links_facebook && (
        <li>
          <a
            href={settings.links_facebook}
            title="Facebook"
            className="c-nav-list__link"
            target="_blank"
          >
            <i className="o-icon o-icon--medium">
              <FacebookIcon />
            </i>
          </a>
        </li>
      )}
      {settings.links_instagram && (
        <li>
          <a
            href={settings.links_instagram}
            title="Instagram"
            className="c-nav-list__link"
            target="_blank"
          >
            <i className="o-icon o-icon--medium">
              <InstagramIcon />
            </i>
          </a>
        </li>
      )}
      {settings.links_linkedin && (
        <li>
          <a
            href={settings.links_linkedin}
            title="LinkedIn"
            className="c-nav-list__link"
            target="_blank"
          >
            <i className="o-icon o-icon--medium">
              <LinkedInIcon />
            </i>
          </a>
        </li>
      )}
    </ul>
  )
}
