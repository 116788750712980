import React from 'react'
import { AgencyAddress } from '../agency-address'
import SBMarkerIcon from '../../assets/img/icons/ico-sb-marker.svg'
import MailIcon from '../../assets/img/icons/ico-mail.svg'
import PhoneIcon from '../../assets/img/icons/ico-phone.svg'

export default ({ settings }) => {
  return (
    <ul className="c-nav-list c-nav-list--address">
      <li>
        <i className="o-icon">
          <SBMarkerIcon />
        </i>
        <AgencyAddress />
      </li>
      {settings.contact_email && (
        <li>
          <i className="o-icon">
            <MailIcon />
          </i>
          {settings.contact_email}
        </li>
      )}
      {settings.contact_phone && (
        <li>
          <i className="o-icon">
            <PhoneIcon />
          </i>
          {settings.contact_phone}
        </li>
      )}
    </ul>
  )
}
