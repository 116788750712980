import React from 'react'
import { useAgency } from '../hooks/use-agency'
import { useLocale } from '../hooks/use-locale'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

function LocaleSelector({ mobile }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const { settings, agency } = useAgency()
  const currentLocale = useLocale()

  const supportedLocales = settings.languages
  // if only one locale, don't render anything
  if (supportedLocales.length <= 1) {
    return null
  }

  if (mobile) {
    return (
      <nav className="c-nav-lang print-display-none">
        <ul className="o-list c-nav-lang__list is-visible">
          {supportedLocales.map((locale) => {
            return (
              <li className="c-nav-lang__item">
                <a
                  href={`/${locale}/?remember-locale`}
                  className="c-nav-lang__link"
                  className={classNames('c-nav-lang__link', {
                    'is-selected': locale === currentLocale,
                  })}
                >
                  {locale}
                </a>
              </li>
            )
          })}
        </ul>
      </nav>
    )
  }

  return (
    <nav className="c-nav-lang print-display-none">
      <a
        className={classNames('c-nav-lang__trigger ', {
          'is-active': isOpen,
        })}
        onClick={() => setIsOpen((open) => !open)}
      >
        <span className="u-hide-bp3-min uppercase">{currentLocale}</span>
        <span className="u-hide-bp3">
          <FormattedMessage id={currentLocale} />
        </span>
      </a>
      {isOpen && (
        <ul className="o-list c-nav-lang__list is-visible">
          {supportedLocales.map((locale) => {
            return (
              <li className="c-nav-lang__item">
                <a
                  href={`/${locale}/?remember-locale`}
                  className="c-nav-lang__link"
                >
                  <FormattedMessage id={locale} />
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </nav>
  )
}

export default injectIntl(LocaleSelector)
