import React from 'react'
import { injectIntl } from 'react-intl'
import { useAgency } from '../hooks/use-agency'
import { useLocale } from '../hooks/use-locale'
import SEO from './seo'

const AgencySEO = injectIntl(function HomeSEO({ title, intl, description }) {
  const { settings } = useAgency()
  const locale = useLocale()
  title = title ? intl.formatMessage({ id: title }) : null

  description = description
    ? intl.formatMessage({ id: description })
    : settings[`tagline_${locale}`] ||
      intl.formatMessage({ id: 'cta.homepage' })

  const image = getHomeImage(settings)
  const video = getHomeVideo(settings)

  return (
    <SEO
      title={title}
      description={description}
      image={image}
      video={video}
      locale={locale}
    />
  )
})

export default AgencySEO

function getHomeImage(settings) {
  if (settings.homepage_cover === 'borderless_image') {
    return settings.homepage_cover_image ?? `/img/default_ilustration.png`
  }
  if (settings.homepage_cover === 'image') {
    return settings.homepage_cover_image ?? `/img/default_ilustration.png`
  }
  if (settings.homepage_cover === 'default') {
    return `/img/default_ilustration.png`
  }
  return null
}

function getHomeVideo(settings) {
  if (settings.homepage_cover === 'video') {
    return settings.homepage_cover_video
  }
  return null
}
