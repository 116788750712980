import React from 'react'
import Link from './localized-link'
import H1 from '../theming/H1'

export default ({ siteTitle, logoId }) => {
  const logoUrl = logoId
    ? `https://cdn.sweepbright.com/companies/presets/agency-website-company-logo/${logoId}`
    : '/img/svg/logo.svg'

  return (
    <H1
      className="c-logo"
      style={{
        backgroundImage: `url("${logoUrl}")`,
      }}
    >
      <Link to="/" className="c-logo__link">
        {siteTitle}
      </Link>
    </H1>
  )
}
