import React from 'react'
import { Helmet } from 'react-helmet'
import { useAgency } from '../hooks/use-agency'
import { useLocation } from '@reach/router'

const SEO = ({ title, description, image, article, video, locale }) => {
  const { pathname } = useLocation()
  const { agency, settings } = useAgency()
  const siteUrl = `https://${settings.agency_website_hostname}${pathname}`
  const defaultTitle = agency.name
  title = title ? `${agency.name} - ${title}` : defaultTitle

  return (
    <Helmet title={title}>
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={siteUrl} />
      {image && <meta name="image" content={image} />}

      {/* OpenGraph Tags */}
      {title && <meta property="og:title" content={title} />}
      <meta property="og:url" content={siteUrl} />
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {video && <meta property="og:video" content={video} />}
      <meta property="og:locale" content={locale} />
      {article && <meta property="og:type" content="article" />}

      {/* Twitter Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  )
}

export default SEO
