import React from 'react'
import Link from '../localized-link'
import { FormattedMessage } from 'react-intl'

export default ({ locale, settings }) => {
  return (
    <ul className="c-nav-list">
      {settings[`about_${locale}`]?.trim() && (
        <li>
          <Link className="c-nav-list__link" to="/about">
            <FormattedMessage id="page.about" />
          </Link>
        </li>
      )}
      {settings[`description_${locale}`]?.trim() && (
        <li>
          <Link className="c-nav-list__link" to="/services">
            <FormattedMessage id="page.services" />
          </Link>
        </li>
      )}
      <li>
        <Link className="c-nav-list__link" to="/personal-updates">
          <FormattedMessage id="page.get-personal-updates" />
        </Link>
      </li>
    </ul>
  )
}
