import React from 'react'
import Button from '../../theming/Button'
import { FormattedMessage } from 'react-intl'

export default () => {
  return (
    <p className="toolbar">
      <Button
        to="/contact"
        className="c-button--alpha c-button--md c-button--block"
      >
        <FormattedMessage id="page.contact-us" />
      </Button>
    </p>
  )
}
